const FaceBookNew = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM15.9963 8C20.4125 8 23.9926 11.5801 23.9926 15.9963C23.9926 19.9874 21.0685 23.2955 17.2457 23.8954V18.3077H19.1089L19.4634 15.9963H17.2457V14.497C17.2457 13.864 17.5556 13.2476 18.5488 13.2476H19.5571V11.2797C19.5571 11.2797 18.6418 11.1235 17.7672 11.1235C15.9407 11.1235 14.7469 12.2305 14.7469 14.2346V15.9963H12.7166V18.3077H14.7469V23.8954C10.9241 23.2955 8 19.9874 8 15.9963C8 11.5801 11.5801 8 15.9963 8Z"
        fill="white"
      />
    </svg>
  )
}

export default FaceBookNew
